<template>
  <a-form-model
    ref="form"
    :hide-required-mark="true"
    :label-col="{ span: 4, style: { textAlign: 'left' } }"
    :model="form"
    :rules="rules"
    :wrapper-col="{ span: 20 }"
  >
    <a-form-model-item label="用户名" prop="username" style="margin-bottom: 24px;">
      <a-input v-model="form.username" :max-length="128" placeholder="请输入用户名" size="large"></a-input>
    </a-form-model-item>
    <a-form-model-item label="密码" prop="password" style="margin-bottom: 24px;">
      <a-input-password v-model="form.password" laceholder="请输入密码" size="large"></a-input-password>
    </a-form-model-item>
    <a-form-model-item label="验证码" prop="code">
      <div style="display: -webkit-flex;">
        <a-input v-model="form.code" laceholder="请输入验证码" size="large" @pressEnter="handleLogin"></a-input>
        <code-validator @code="v => { this.code = v }"></code-validator>
      </div>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 24 }">
      <a-button :block="true" :loading="loading" size="large" type="primary" @click="handleLogin">登录</a-button>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import { mapActions } from 'vuex'
import config from '@/config'
import CodeValidator from './modules/CodeValidator'

export default {
  name: 'Login',
  components: { CodeValidator },
  data () {
    return {
      form: {},
      rules: {
        username: [{ message: '请输入用户名', required: true, trigger: 'blur' }],
        password: [{ message: '请输入密码', required: true, trigger: 'blur' }],
        code: [
          { message: '请输入验证码', required: true, trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.code) {
                callback(new Error('验证码不正确'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      },
      code: '',
      loading: false
    }
  },
  methods: {
    ...mapActions(['Login']),
    handleLogin () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const data = {
            username: this.form.username,
            password: this.form.password
          }
          this.Login(data).then(res => {
            this.$notification.success({ message: res.message, description: `欢迎使用 ${config.title}` })
            this.$router.push({ name: 'BigScreen' })
            this.$refs.form.resetFields()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
