<template>
  <canvas id="canvas" @click="refresh"></canvas>
</template>

<script>
export default {
  name: 'CodeValidator',
  data () {
    return {
      canvas: null,
      ctx: null,
      code: ''
    }
  },
  mounted () {
    this.initCanvas()
  },
  methods: {
    refresh () {
      this.code = ''
      this.initCanvas()
    },
    randomColor () {
      const r = Math.floor(Math.random() * 230)
      const g = Math.floor(Math.random() * 230)
      const b = Math.floor(Math.random() * 230)
      return 'rgb(' + r + ',' + g + ',' + b + ')'
    },
    randomBC () {
      const r = Math.floor(Math.random() * (255 - 180) + 180)
      const g = Math.floor(Math.random() * (255 - 220) + 220)
      const b = Math.floor(Math.random() * (255 - 180) + 180)
      return 'rgb(' + r + ',' + g + ',' + b + ')'
    },
    randomFontSize () {
      return Math.round(Math.random() * (36 - 16) + 16)
    },
    initCanvas () {
      this.canvas = document.getElementById('canvas')
      this.ctx = this.canvas.getContext('2d')
      this.canvas.width = 150
      this.canvas.height = 40
      this.ctx.fillStyle = this.randomBC()
      this.ctx.globalAlpha = 0.3
      this.ctx.fillRect(0, 0, 150, 40)
      this.ctx.globalAlpha = 1
      const arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
      for (let i = 0; i < 4; i++) {
        const num = Math.floor(Math.random() * arr.length)
        this.code += arr[num] // 记录随机数
        const deg = Math.random() - 0.5
        const x = 23 + i * 30
        const y = 25 + Math.random() * 8
        const fs = this.randomFontSize()
        this.ctx.font = `bold ${fs}px 微软雅黑`

        this.ctx.translate(x, y)
        this.ctx.rotate(deg)

        this.ctx.fillStyle = this.randomColor()
        this.ctx.fillText(num, 0, 0)

        this.ctx.rotate(-deg)
        this.ctx.translate(-x, -y)
      }
      this.drawDots()
      this.drawLine()
      this.$emit('code', this.code)
    },
    drawDots () {
      for (let i = 0; i <= 300; i++) { // 验证码上显示小点
        this.ctx.strokeStyle = this.randomColor()
        this.ctx.beginPath()
        const x = Math.random() * 150
        const y = Math.random() * 40
        this.ctx.moveTo(x, y)
        this.ctx.lineTo(x + 1, y + 1)
        this.ctx.stroke()
      }
    },
    drawLine () {
      for (let i = 0; i <= 8; i++) { // 验证码上显示线条
        this.ctx.strokeStyle = this.randomColor()
        this.ctx.beginPath()
        this.ctx.moveTo(Math.random() * 150, Math.random() * 40)
        this.ctx.lineTo(Math.random() * 150, Math.random() * 40)
        this.ctx.stroke()
      }
    }
  }
}
</script>

<style lang="less" scoped>
canvas {
  width: 150px;
  height: 40px;
  margin-left: 20px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
}
</style>
